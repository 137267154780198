import React, { Component } from "react";
import Slider from "react-slick";
import rpa1 from "../Assets/slider rpa/1.png";
import rpa2 from "../Assets/slider rpa/2.png";
import rpa3 from "../Assets/slider rpa/3.png";
import rpa4 from "../Assets/slider rpa/4.png";
import rpa1responsive from "../Assets/slider rpa/1responsive.png";
import rpa2responsive from "../Assets/slider rpa/2responsive.png";
import rpa3responsive from "../Assets/slider rpa/3responsive.png";
import rpa4responsive from "../Assets/slider rpa/4responsive.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
    };

    return (
      <div id="news" className="container-carrousel-slider">
        <div className="slider-container">
          <Slider {...settings}>
            <div className="wrapper">
              <img className="slider-img" src={rpa1} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={rpa2} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={rpa3} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={rpa4} alt="" />
            </div>
          </Slider>
        </div>

        <div className="slider-container-responsive">
          <Slider {...settings}>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa1responsive}
                alt=""
              />
            </div>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa2responsive}
                alt=""
              />
            </div>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa3responsive}
                alt=""
              />
            </div>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa4responsive}
                alt=""
              />
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
