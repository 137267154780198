import instagram from "../Assets/instagram.png";
import linkedin from "../Assets/linkedin.png";
import facebook from "../Assets/facebook.png";

const News = () => {
  return (
    <div className="news-container wrapper">
      <h1>Para conocer las novedades seguinos en nuestras redes!</h1>

      <a href="https://www.instagram.com/automationbg.rpa/" target="_blank">
        <img src={instagram} alt="Icono de instagram" />
      </a>

      <a href="https://www.linkedin.com/company/automation-bg/" target="_blank">
        <img src={linkedin} alt="Icono de linkedin" />
      </a>

      <a href="https://www.facebook.com/Automationbgrpa" target="_blank">
        <img src={facebook} alt="Icono de facebook" />
      </a>
    </div>
  );
};

export default News;
