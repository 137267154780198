import { useState } from "react";
import VIDEO from "../Assets/rpa-video.mp4";

const RpaVideo = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div className="ht-text-details">
      <h1 className="hb-text wrapper">
        BIENVENIDO A LA <br />
        4ª REVOLUCIÓN INDUSTRIAL
      </h1>
      <p>
        El RPA genera un impacto positivo en el rendimiento de la empresa.
        Permite a los empleados más cualificados impulsar su creatividad y
        optimizar sus procesos de trabajo.
      </p>

      <button className="rpa-open-video" onClick={handleClick}>
        Ver video
      </button>
      {open && (
        <div className="rpa-video-container">
          <button onClick={() => setOpen(false)}></button>
          <video autoPlay>
            <source src={VIDEO} type="video/mp4"></source>
          </video>
        </div>
      )}
    </div>
  );
};

export default RpaVideo;
