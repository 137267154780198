const WhoWeAre = () => {
  return (
    <div id="quienessomos" className="who-we-are">
      <div id="quienessomos-scroll" className="scroll-fix"></div>
      <div className="who-we-are-container wrapper">
        <h1>
          Nos ajustamos según tu necesidad. <br />
          CREAMOS soluciones de software a la medida de tu NEGOCIO.
        </h1>

        <div className="who-we-are-details">
          <p>
            Somos una empresa de tecnología, que se mantiene a la vanguardia y
            adopta soluciones innovadoras para que tu negocio pueda mejorar la
            eficiencia y aumentar la productividad, y nuestros servicios de RPA
            son una excelente manera de lograrlo.
          </p>
        </div>

        <h2>Hacemos realidad la transformación virtual.</h2>
      </div>
    </div>
  );
};

export default WhoWeAre;
