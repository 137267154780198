const PancakeMenu = ({ handleClick, classname }) => {
  return (
    <div>
      <button onClick={handleClick} className={classname}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default PancakeMenu;
