import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { objectToFormData } from "../utils";
import axios from "axios";

const Contact = () => {
  const [recaptchavalue, setRecaptchaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const recaptchaRef = useRef();
  const [fields, setFields] = useState({
    name: "",
    email: "",
    phone: "",
    matter: "",
    message: "",
  });

  const handleChangeField = (field, value) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submitDisabled = () => {
    if (loading) return true;

    const formFields = Object.keys(fields);
    let empty = false;

    for (let field of formFields) {
      const value = fields[field].trim();
      if (value === "") {
        empty = true;
        break;
      }
    }

    return empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/mail.php`,
        data: objectToFormData({
          nombre: fields.name,
          email: fields.email,
          telefono: fields.phone,
          asunto: fields.matter,
          mensaje: fields.message,
          "g-recaptcha-response": recaptchavalue,
        }),
      });

      if (response.data.status !== "ok") throw new Error();

      await Swal.fire({
        title: "Mensaje enviado!",
        text: "Se ha enviado correctamente!",
        icon: "succes",
      });

      setFields({
        name: "",
        email: "",
        phone: "",
        matter: "",
        message: "",
      });

      setRecaptchaValue("");
      recaptchaRef.current?.reset();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Lo sentimos, se ha producido un error, intente nuevamente.");
    }
  };

  return (
    <div>
      <div id="contacto" className="contact-details-container">
        <div id="contacto-scroll" className="scroll-fix"></div>
        <h1>Contacto</h1>

        <div className="wrapper ">
          <form className="form-container" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                value={fields.name}
                onChange={(e) => handleChangeField("name", e.target.value)}
                placeholder="Nombre"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                value={fields.email}
                onChange={(e) => handleChangeField("email", e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={fields.phone}
                onChange={(e) => handleChangeField("phone", e.target.value)}
                placeholder="Teléfono"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={fields.matter}
                onChange={(e) => handleChangeField("matter", e.target.value)}
                placeholder="Asunto"
              />
            </div>
            <div className="form-group">
              <textarea
                name="menssage"
                cols="30"
                rows="10"
                type="text"
                value={fields.message}
                onChange={(e) => handleChangeField("message", e.target.value)}
                placeholder="Mensaje"
              ></textarea>
            </div>
            {error && <p>{error}</p>}{" "}
            <div className="recaptcha-container">
              <ReCAPTCHA
                className="recaptcha"
                sitekey="6Ldf_PQmAAAAAHXGru0wrY4gDJ_Y_sxQfdH4La3X"
                onChange={setRecaptchaValue}
                ref={recaptchaRef}
              />
            </div>
            <button className="button-contact" disabled={submitDisabled()}>
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
