import React from "react";

import acordionicon5 from "../Assets/acordion-icons/eficiencia.png";
import acordionicon6 from "../Assets/acordion-icons/boton.png";
import acordionicon7 from "../Assets/acordion-icons/interacciones.png";
import acordionicon8 from "../Assets/acordion-icons/concentracion.png";

const Acordion = () => {
  const carrouselItems2 = [
    {
      nombre: "86% Mayor eficiencia",

      imagen: acordionicon5,
    },
    {
      nombre: "60% Más concentración en tareas importantes",
      imagen: acordionicon8,
    },
    {
      nombre: "71% Menos tareas manuales",
      imagen: acordionicon6,
    },
    {
      nombre: "66% Más de interacción humana",
      imagen: acordionicon7,
    },
  ];

  return (
    <div className="principal-container-acordion-carousel">
      <div className="container-rpa-benefits wrapper">
        <h1 className="container-acord">
          Beneficios experimentados por los usuarios del RPA
        </h1>

        <div className="container-carrousel wrapper">
          {carrouselItems2.map((item) => (
            <div key={item.nombre} className="carousel-detail">
              <img className="carrousel-img" src={item.imagen} alt="" />
              <h1>{item.nombre}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Acordion;
