import background from "../Assets/video.mp4";
import responsive from "../Assets/responsive-video.mp4";
import Typewriter from "typewriter-effect";
import Cards from "./Cards";
import Acordion from "./Acordion";
import RpaVideo from "./RpaVideo";
import Contact from "./Contact";
import WhoWeAre from "./WhoWeAre";
import Slider from "./Slider";
import News from "./News";
import rocketbot from "../Assets/licenses/rocketbot.png";
import blueprism from "../Assets/licenses/blueprism.png";
import uipath from "../Assets/licenses/uipath.png";
import primerfondo from "../Assets/poster.jpg";
import segundofondo from "../Assets/poster-responsive.png";

const Main = () => {
  return (
    <div className="principal-container">
      <div className="text">
        {/*  <div className="mask"></div> */}
        <div className="text-detils-home">
          {/*    <h1>Creadores de </h1> */}
          <Typewriter
            options={{
              strings: [
                "RPA - AUTOMATIZACIÓN ROBÓTICA DE PROCESOS",
                "LOS ROBOTS, PEQUEÑOS AYUDANTES",
              ],
              autoStart: true,
              loop: true,
              skipAddStyles: true,
              wrapperClassName: "texts-spans",
              cursorClassName: "cursor-text",
              delay: 30,
              deleteSpeed: 20,
            }}
          />
        </div>
      </div>
      <video
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
        poster={primerfondo}
      >
        <source src={background} type="video/mp4" />
      </video>

      <video
        className="video-responsive"
        autoPlay
        loop
        muted
        playsInline
        poster={segundofondo}
      >
        <source src={responsive} type="video/mp4" />
      </video>

      <WhoWeAre />

      <div className="licenses-container wrapper">
        <h1>Trabajamos con las siguientes licencias</h1>
        <div className="licenses-details">
          <a href="https://rocketbot.com/es/" target="_blank">
            <img src={rocketbot} alt="logo de rocketbot" />
          </a>
          <a href="https://www.blueprism.com/es/" target="_blank">
            <img src={blueprism} alt="logo de blueprism" />
          </a>
          <a href="https://www.uipath.com/newsroom" target="_blank">
            <img src={uipath} alt="logo de uipath" />
          </a>
        </div>
      </div>
      <Cards />

      <RpaVideo />

      <Acordion />

      <Slider />

      <News />

      <Contact />
    </div>
  );
};

export default Main;
