import logobg from "../Assets/logo.png";
import wp from "../Assets/wp.png";
import devlogo from "../Assets/logo-dev.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-details wrapper">
        <img
          src={logobg}
          className="logo-principal"
          alt="Logo principal de cema arquitectura"
        />

        <div className="info-footer">
          <p>Virrey del Pino 2720 CABA</p>
          <p>Lunes a viernes de 9 a 18hs </p>
        </div>

        <div className="info-footer">
          <p>Contactos@automationbg.com.ar</p>
          <div className="wp-container">
            <a
              href="https://api.whatsapp.com/send?phone=541131392468"
              target="_blank"
            >
              <img className="wp-contact" src={wp} alt="logo de whatsapp" /> +54
              11 31392468
            </a>{" "}
            <a
              href="https://api.whatsapp.com/send?phone=541150193968"
              target="_blank"
            >
              <img className="wp-contact" src={wp} alt="logo de whatsapp" /> +54
              11 50193968
            </a>
          </div>
        </div>

        <div className="info-footer site-info">
          <p>Sitio:</p>
          <a href="https://athom.com.ar/" target="_blank">
            <img className="logo-developer" src={devlogo} alt="Logo de Athom" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
