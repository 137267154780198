const Cards = () => {
  return (
    <div id="rpa" className="cards-principal wrapper">
      <div id="rpa-scroll" className="scroll-fix"></div>
      <h1>RPA</h1>
      <div className="cards-container wrapper">
        <div className="tarjeta-wrap">
          <div className="tarjeta">
            <div className="adelante card1"></div>
            <div className="atras">
              <p>
                Un RPA es un Software que tiene la capacidad de imitar los
                comportamientos de uno o varios empleados dentro de un sistema,
                repitiendo de manera exacta las tareas o procesos.
              </p>
            </div>
          </div>
        </div>
        <div className="tarjeta-wrap">
          <div className="tarjeta">
            <div className="adelante card2"></div>
            <div className="atras">
              <div>
                <p>Un RPA entre otras cientos de cosas puede:</p>
                <ul>
                  <li>- Capturar y leer documentos.</li>
                  <li>- Ejecutar cálculos complejos.</li>
                  <li>- Crear carpetas, generar y mover archivos.</li>
                  <li>
                    - Leer correos electrónicos, enviar, recibir, seleccionar,
                    inclusive descargar adjuntos.
                  </li>
                  <li>
                    - Procesar PDF’s o imágenes extrayendo su información.
                  </li>
                  <li>- Navegar y operar páginas web, CRM, ERP, etc.</li>
                  <li>
                    - Operar bases de datos, SQLServer, Oracle, SAP, etc.{" "}
                  </li>
                  <li>
                    - Operar con sistemas de Gestión, Tango, Bejerman, Odoo,
                    etc.
                  </li>
                  <li>- Trabajar en Aplicaciones de escritorio</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tarjeta-wrap">
          <div className="tarjeta">
            <div className="adelante card3"></div>
            <div className="atras">
              <div>
                <ul>
                  <li>1- Relevamiento proceso manual​</li>
                  <li>2- Definición de la lógica del RPA</li>
                  <li>3- Desarrollo del Proceso</li>
                  <li>4- Pruebas integrales de calidad</li>
                  <li>5- Implementación de la solución</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
