const NavBar = ({ open, setOpen }) => {
  const closeMenu = () => setOpen(false);
  return (
    <div className={`nav-bar ${open ? "nav-bar-open" : ""}`}>
      <nav>
        <ul>
          <li className="links-details">
            <a onClick={closeMenu} href="#" className="nav-link">
              Inicio
            </a>
          </li>
          <li className="links-details">
            <a
              onClick={closeMenu}
              href="#quienessomos-scroll"
              className="nav-link"
            >
              {" "}
              Quienes somos
            </a>
          </li>
          <li className="links-details">
            <a onClick={closeMenu} href="#rpa-scroll" className="nav-link">
              {" "}
              RPA
            </a>
          </li>

          <li className="links-details">
            <a onClick={closeMenu} href="#contacto-scroll" className="nav-link">
              {" "}
              Contacto
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
