import { useState } from "react";
import PancakeMenu from "./PancakeMenu";
import logocema from "../Assets/logo.png";
import NavBar from "./NavBar";

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="header-container">
        <div className="bottom-header">
          <div className="bottom-header-details wrapper">
            <img
              className="logo-principal"
              src={logocema}
              alt="Logo principal de Cerco Perimetral"
            />
            <PancakeMenu
              classname={`hamburguer-menu ${open ? "open" : ""}`}
              handleClick={handleClick}
            />
            <NavBar setOpen={setOpen} open={open} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
